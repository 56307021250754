@import '@fortawesome/fontawesome-free/css/all.min.css';

body, h1, h2, h3, h4, h5, h6, p, div, span, button, input, select, option, textarea {
    font-family: 'Manrope', sans-serif;
}

:root {
    --margin-top: 2rem;
    --margin-right: 2rem;
    --margin-bottom: 4rem;
    --margin-left: 2rem;
    --background-color: #2b2e31;
    --text-color: #2b2e31;
}

body {
    display: flex;
    background-color: var(--background-color);
    color: var(--text-color);
    height: 100vh;
    width: 100vw;
    margin: 0;
    overflow: hidden;
    overflow-x: hidden;
    background-size: cover;
    background-position: top left;
    user-select: none;
}

button {
    background-color: var(--background-color);
    color: var(--text-color);
    border: 1px solid var(--text-color);
    padding: 0.5rem 1rem;
    cursor: pointer;
}

button:hover {
    background-color: var(--text-color);
    color: var(--background-color);
}

select {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0px;
    border: 1px solid var(--text-color);
    background-color: #2b2e3158;
    color: #2b2e31;
}

/* Landing page */
.landing-container {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  background-size: fixed;
  background-position: center;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction:column;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  user-select: none;
}

/* Background Container */
.background-container, .fading-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 0% center;
    transition: opacity 2s ease-in-out;
    animation: continuousPan 30s linear infinite;
    z-index: -1;
}

.animate-background {
    animation: continuousPan 30s linear infinite;
}

.fading-container {
    opacity: 0;
}

/* Fade-in Animation */
.fade-in {
    opacity: 1;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes continuousPan {
    0% { background-position: 0% center; }
    100% { background-position: 100% center; }
  }
  

/* Landing Content */
.landing-content {
    position: relative;
    z-index: 1;
    color: #fff7e7;
    text-align: center;
}


.blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(50px);
    z-index: 0;
}

/* Main content styles */
.main-content {
    position: absolute;
    top: 16%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #2b2e31;
    z-index: 2;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    width: 70%;
}

.landing-logo {
    position: relative;
    top: 0; 
    left: 50%;
    transform: translate(-50%, 0);
    width: 40%; 
    height: auto;
    z-index: 2;
    display: flex;
}

.landing-site-name {
    font-size: 6.5rem;
    /* margin-top: 20rem; */
    margin: 0;
    font-weight: bold;
    color: #2b2e31; 
    position: center;
    z-index: 10;
}

.landing-headline{
    font-size: 1.375rem;
    font-weight:lighter;
    margin-top: 1rem;
    color: #2b2e31;
}

.mailchimp-form iframe {
    margin-top: 2rem;
}

/* Sales section */
.sales-section {
    margin-top: 100vh;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 3rem;
    z-index: 5;
    padding: 0;
}

.sales-point {
    display: flex;
    justify-content: space-between;
    align-items: left;
    margin: 0px 20%;
    padding: 1rem;
    color: #2b2e31;
    opacity: 0;
    transform: translateY(3rem);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.sales-point .text-container {
    width: 80%;
    padding: 1rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sales-point.visible {
    opacity: 1;
    transform: translateY(0);
}

.sales-point img {
    width: auto;
    max-width: 30%;
    height: auto;
    object-fit: contain;
    transition: transform 0.3s ease;
}

.sales-point.left {
    flex-direction: row;
}

.sales-point.left img {
    margin-right: 2rem;
}

.sales-point.right {
    flex-direction: row-reverse;
}

.sales-point.right img {
    margin-left: 2rem;
}

.sales-point h3 {
    font-size: 2rem;
    font-weight: bold;
    color: #2b2e31;
    margin-bottom: 0.5rem;
    margin-top: 0px;
}

.sales-point p {
    font-size: 1.375rem;
    line-height: 1.6;
    color: #2b2e31;
    margin-bottom: 0;
}

/* Footer styles */
.footer {
    position: relative;
    width: 100%;
    padding: 0.5rem;
    background-color: #2b2e31;
    color: #fff7e7;
    text-align: center;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social-links {
    display: flex;
    gap: 1rem;
    margin-left: 2rem;
}

.social-links a {
    color: #fff7e7;
    font-size: 1.5rem;
    width: auto; 
    height: 3rem;
    display: inline-block; 
    text-align: center;
    line-height: 3rem;
}

.social-links a:hover {
    color: #ef4467;
    background-color: transparent;
}

.about-section .about-text {
    display: none;
    margin-top: 1rem;
    color: #fff7e7;
    text-align: right;
    margin-right: 2rem;
    
}

.about-section, .about-section * {
    color: #fff7e7; 
}

.about-section:hover {
    color: #ef4467; 
}

.about-section .about-text.visible {
    display: block;
    color: #fff7e7;
}

/* ----- MAILCHIMP ----- */


.mailchimp-form {
    position: relative;
    z-index: 20;
    margin-bottom: 2rem;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    color: #2b2e31;
    width: 100%;
    margin: 0px 20%;
    margin-bottom: 4rem;
    margin-top: 4rem;
}

#shell {
    background: #2b2e3100;
    padding: 0;
    z-index: 20;
    margin-top: 2rem;
}

#indicates-required {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

#mc_embed_signup_scroll .h2 {
    font-size: 2rem;
    font-weight: bold;
    /* margin-bottom: 0.2rem; */
    color: #2b2e31;
    justify-content: center;
    margin: 0;
}

#mc_embed_signup_scroll .p {
    font-size: 1rem;
    margin-bottom: 5px;
    color: #2b2e31;
    justify-content: center;
}

#mc_embed_shell {
    z-index: 20;

}

#mc_embed_signup {
    background: #fff7e700;
    padding: 0;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    z-index: 20;
}

/* Label Styles */
#mc_embed_signup label {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    background: #2b2e3100;
    color: #2b2e31;
}

#mc-field-group {
    background: #fff7e700;
    margin-top: 0px;
}

/* Input Field Styles */
#mc_embed_signup input[type="email"],
#mc_embed_signup input[type="text"] {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    background: #fff7e7;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
}

#mc-embedded-subscribe-form.validate {
    background: #2b2e3100;
}

#mc_embed_signup {
    background: #2b2e3100;
}

.input {
    background: #fff7e7;
}


#mc_embed_signup input[type="email"]:focus,
#mc_embed_signup input[type="text"]:focus {
    border-color: #2b2e31; 
    outline: none; 
}

/* Submit Button Styles */
#mc_embed_signup input[type="submit"] {
    background-color: #ef4467;
    color: #fff7e7;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    cursor: pointer;
    width: 30%;
    justify-content: center;
    transition: background-color 0.3s ease;
    box-shadow: 0 0 10px #fff7e7;
    font-weight: bold;
}

#mc_embed_signup input[type="submit"]:hover {
    background-color: #fff7e7;
    color: #ef4467;
}

#mc_embed_signup .mce_inline_error {
    color: #ef4467;
    font-size: 1rem;
    margin-bottom: 10px;
}

#mc_embed_signup .mce_success_msg {
    color: #2ecc71; 
    font-size: 1rem;
}

#mc_embed_signup .mc-field-group {
    margin-bottom: 1rem;
    background-color: #2b2e3100;
}

#mc_embed_signup .mc-field-group input[type="checkbox"] {
    margin-right: 0.5rem;
}

#mc_embed_signup .mc-field-group label {
    font-size: 1rem;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
    .main-content {
        top: 10%;
    }

    .landing-logo {
        width: 70vw;
    }

    .landing-site-name {
        font-size: 3rem;
    }

    .landing-headline {
        font-size: 1rem;
    }

    .sales-point {
        flex-direction: column;
        text-align: center;
        margin: 0;
    }
    .sales-point.left, .sales-point.right {
        flex-direction: column;
    }

    .sales-point.left, .sales-point.right {
        margin: 0;
    }

    .sales-point.left img, .sales-point.right img {
        margin: 0;
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .mailchimp-form {
        width: 90%;
        margin: 0;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }
    
}